import React from "react";
import { Panel, useComponentContext } from "lib/components";
import { AuthType } from "lib/util/AuthType";
import { getLogger } from "lib/util";
import { OrderConfirmationHeader } from "portal/customer/OrderConfirmationHeader";
import { QuoteSummaryPanel } from "portal/customer/QuoteSummaryPanel";
import { StopTable } from "portal/customer/StopTable";
import { FreightItemsTable } from "portal/customer/FreightItemsTable";
import { AdditionalDetails } from "portal/customer/AdditionalDetails";
import { PaymentTerms } from "portal/customer/PaymentTerms";
import { HandlingReqsTable } from "portal/customer/HandlingReqsTable";
import { CustomerType, PanelMode, PageType } from "../ControlConstants";

const log = getLogger("portal.customer.brokerage-ltl-quote.Confirmation");

export function Confirmation(props) {
  log.debug("Confirmation %o  %o", props, useComponentContext());
  const parentType = "ltl-order";
  const stopTableProps = {
    parentType: parentType,
    tableCaption: "Pickup and Delivery"
  }
  const freightItemsTable = FreightItemsTable(CustomerType.ASSET, PanelMode.DISPLAY);
  const handlingReqsTable = HandlingReqsTable(3, null, {noMargin: true, noPadding: true});

  return (
    <Panel data={props.data} fillRow modelName={"dispatch/ltl-order"}>
      <OrderConfirmationHeader />
      <Panel fillRow>
        <QuoteSummaryPanel data={props.data} type="Order" idField="id" stopsVisible={false} totalVisible={false} carrierVisible={false} freightSummaryVisible={false} otherChargesVisible={false} ratingDetailsVisible={false} disclaimerVisible={false} buttonLook="primary1" noRowBreak />
        <Panel fillRow>
          <StopTable {...stopTableProps} fillRow />
          {freightItemsTable}
          <AdditionalDetails parentType={parentType} usePrintableVersion />
          <PaymentTerms usePrintableVersion />
          {handlingReqsTable}
        </Panel>
      </Panel>
    </Panel>
    )
}

export function getPageOptions() {
  return {
    auth: [AuthType.CUSTOMER, AuthType.LOCATION],
    title: "Order Confirmation"
  };
}
