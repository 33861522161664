import React, { useState } from "react";
import { Dialog as MuiDialog, DialogTitle, DialogContent, IconButton } from "@material-ui/core";
import { Spacer, Typography } from "common";
import classNames from "core/utils/classNames";
import CloseIcon from "@material-ui/icons/Close";
import { getLogger, getUIContext } from "lib/util";
import { useDialogStyles } from "./DialogStyles";
import { evaluateProp } from "./ComponentUtil";
import { Label, Panel } from ".";
import { getUserSettings } from "lib/util/MainPageContext";

const log = getLogger("lib.components.Dialog");

export function Dialog({
	className,
	title = null,
	actions = null,
	open = false,
	onClose,
	titleBackgroundColor = "#EEEEEE",
  titleColor,
  content,
  contentStyle,
	...restProps
}) {
  const classes = useDialogStyles();
	return (
		<MuiDialog disableBackdropClick={true} {...restProps} className={classNames(classes.root, className)}
			open={open} onClose={onClose} aria-labelledby="dialog" >
			{getTitle(classes, title, onClose, titleBackgroundColor, titleColor)}
			<DialogContent className={classes.content} style={contentStyle}>
        <Panel fillHeight fillRow>
          {content}
        </Panel>
      </DialogContent>
		</MuiDialog>
	);
}

export function DialogPlaceholder() {
  let [dialogContent, setDialogContent] = useState(null);
  getUIContext().setDialogContent = setDialogContent;
  return dialogContent;
}

function getTitle(classes, title, onClose, titleBackgroundColor, titleColor) {
	let titleComp;
	if (title != null)
		titleComp = (
			<Typography className={classNames(classes.title)} id="dialog-title" variant="h6" fontWeight={500} style={{color: titleColor}}>
				{title}
			</Typography>
		);
	return (
		<header className={classes.header} style={{backgroundColor: titleBackgroundColor, color: titleColor}}>
      {titleComp}
			<Spacer grow />
			<IconButton style={{marginRight:8, marginTop:2}} onClick={onClose} style={{color:titleColor}}>
				<CloseIcon />
			</IconButton>
		</header>
	);
}

export function closeDialog() {
	getUIContext().setDialogContent(null);
}

export function showDialog(title, dialogContent, dialogProps) {
	if (getUIContext().setDialogContent == null) {
		log.error("Cannot show dialog because main page context has not been set.");
		return;
	}
	if (dialogContent == null) {
		log.info("Dialog opened with no content.");
		return null;
  }
  dialogContent = evaluateProp(null, dialogContent);
  dialogContent = convertStringsToLabels(dialogContent);
  log.debug("Showing dialog with content %o", dialogContent);
	getUIContext().setDialogContent(<Dialog {...dialogProps} open={true} title={title} onClose={closeDialog} content={dialogContent}/>);
}

export function showUnknownResponseDialog(response, error) {
  showDialog("Error", "There was an error handling the response from the server.");
  log.info("Unknown response %o     Error %o", response, error);
}

export function showServerErrorDialog(title, error) {
  let labels = convertStringsToLabels(error.messages);
  if (error.errorTag != null) {
    const link = "mailto:support@mcleodsoftware.com?subject=Error report&body=Received error tag " + error.errorTag + ".";
    labels.push(
      <Panel fillRow>
        <Panel fillRow noRowBreak/>
        <Label caption="Email support" look="primary0" linkURL={link} />
      </Panel>
    );
  }
  showDialog(title, labels);
  log.debug("Server error %o", error);
}

export function showOrderCreationErrorDialog(title, error) {
  let labels = convertStringsToLabels(error.messages);
  const link = "mailto:" + getUserSettings().admin_email_address + "?subject=" + getUserSettings().user_name + " with " + getUserSettings().company_name + " encountered an order creation error on the portal" +
    "&body=Please include details of what you were doing when you received this exception prior to submitting your request.";
  labels.push(
    <Panel fillRow>
      <Label caption="Email for help" look="primary2" linkURL={link} />
    </Panel>
  );
  showDialog(title, labels);
  log.debug("Server error order creation %o", error);
}

function convertStringsToLabels(content) {
  if (typeof content === "string")
    return [<Label look="defaultBold" caption={content} key={0} fillRow fillHeight />];
  if (Array.isArray(content)) {
    if (content != null && content.length > 0 && typeof content[0] !== "string") {
      return content;
    }
    let labels = [];
    for (let i = 0; i < content.length; i++)
      labels.push(<Label look="defaultBold" caption={appendPeriod(content[i])} key={i} fillRow fillHeight />);
    return labels;
  }
  return content;
}

function appendPeriod(message)
{
  if (message == null || message.length === 0) {
    return message;
  }
  let updatedMessage = message;
  if (updatedMessage.substring(updatedMessage.length-1) !== ".") {
    updatedMessage += ".";
  }
  return updatedMessage;
}
