import React from "react";
import { getLogger } from "./Logger";
import { parseDateTime, parseTime, formatDateTime, formatTime, getDateFormat } from "./Date";
import { getDataContextValue } from "lib/components/DataContainer";
import { useState } from "react";
import { useMountEffect } from "core/hooks";
import { Spinner } from "lib/components";
import { callApi } from "./api";
import { showError } from "lib/components/Error";
import { getFunctionForIdentifier } from "./DynamicLoader";
import { getModelValues } from "./ModelValues";

const modelRegex = /[^{}]+(?=})/;
const modelPrefix = "api/";
const cachedModelData = {};
const callApiSearchMethod = "PATCH";
let log;

export const DataModes = {
  NONE: "none",
  SEARCH: "search",
  ADD: "add",
  EDIT: "edit"
}

function getLog() {
  if (log == null)
    log = getLogger("lib.util.ModelUtil");
  return log;
}

/**
 * This function accepts a string and will find and replace variables in that string with values from the
 * data passed in.  Surround the variables with curly braces like "The driver's name is {driver_name}"
 *
 * @param {*} format The string to replace
 * @param {*} data An object from which variables with come
 * @param {*} displayType The type of the data being displayed
 * @param {*} displayFormat Additional formatting string
 */
export function replaceModelData(format, data, displayType, displayFormat) {
  if (format == null)
    return null;
  let match = modelRegex.exec(format);
  while (match != null) {
    let display = getModelDataDisplayValue(data, match[0], displayType, displayFormat);
    if (display == null)
      display = "";
    format = format.substring(0, match.index - 1) + display +
      format.substring(match.index + match[0].length + 1);
    match = modelRegex.exec(format);
  }
  return format;
}

export function getDataListFromContext(context, optionalFieldName) {
  if (context == null)
    return null;
  if (context.data == null || context.data.list == null)
    return null;
  let result = context.data.list;
  if (result != null && optionalFieldName != null) {
    result = result[context.dataIndex].modelData[optionalFieldName];
    //    result = result[optionalFieldName];
  }
  return result;
}

export function getDataFromContext(context, optionalFieldName) {
  let target;
  if (context != null && context.data != null && context.data.mode === DataModes.SEARCH)
    target = context.data.searchValues;
  else {
    const list = getDataListFromContext(context);
    if (list == null || context.dataIndex == null)
      return null;
    target = list[context.dataIndex];
  }
  if (target != null)
    target = target.modelData;
  if (optionalFieldName != null && target != null)
    target = target[optionalFieldName];
  return target;
}

export function getModelDataDisplayValue(dataContext, field, displayType, format, displayField, formatValueFunction, items) {
  if (field != null) {
    const value = getDataContextValue(dataContext, field);
    return getDisplayValue(value, displayType, format, displayField, formatValueFunction, items);
  }
  return null;
}

export function getDisplayValue(value, displayType, format, displayField, formatValueFunction, items) {
  let result = value;
  if (result != null && displayField != null) {
    if (formatValueFunction != null)
      result = callFormatValueFunction(formatValueFunction, result, value);
    else if (typeof result === "object")
      result = result[displayField];
    else if (items !== "undefined" && items !== undefined && items.length > 0) {
      for (let i = 0; i < items.length; i++)
        if (value === items[i].value) {
          value = items[i].caption;
          result = value;
        }
    }
  }
  if (result != null && displayType != null) {
    if (displayType === "currency") {
      if (typeof result === "string")
        result = parseFloat(result);
      result = "$" + result.toFixed(2);
    }
    else if (displayType === "datetime" || displayType === "date")
      result = formatDateTime(parseDateTime(result), getDateFormat(displayType, format));
    else if (displayType === "time") {
      if (typeof result === "object")
        result = JSON.stringify(result);;
      const spacePos = result.lastIndexOf(" ");
      if (spacePos >= 0)
        result = result.substring(spacePos + 1);
      result = formatTime(parseTime(result), getDateFormat(displayType, format));
    }
    else if (displayType === "decimal") {
      if (typeof result === "string") {
        const num = parseFloat(result);
        if (!isNaN(num))
          result = num;
      }
      if (result.toFixed)
        result = result.toFixed(1);
      if (!isNaN(result))
        result = new Intl.NumberFormat("en-US", {style: "decimal", minimumFractionDigits:1}).format(result);
    }
    else if (displayType === "int") {
      if (typeof result === "string") {
        const num = parseInt(result);
        if (!isNaN(num))
          result = num;
      }
      if (!isNaN(result))
        result = new Intl.NumberFormat().format(result);
    }
  }
  return result;
}

export async function fetchCachedModelData(modelName, dataContext, setLoading, onComplete, onError) {
  let result = cachedModelData[modelName];
  if (result != null)
    onComplete(result);
  else {
    fetchModelData(modelName, dataContext, setLoading, (data) => {
      cachedModelData[modelName] = data;
      onComplete(data);
    }, onError);
  }
}

export async function fetchModelData(modelName, context, setLoading, onComplete, onError) {
  getLog().debug("Fetching model data for %s with context %o", modelName, context);
  if (context != null) {
    modelName = replaceModelData(modelName, context);
    getLog().debug("Model name updated to %s based on data context", modelName);
  }
  if (setLoading)
    setLoading(true);
  try {
    const response = await callApi(modelPrefix + modelName, callApiSearchMethod);
    getLog().debug("Fetched data from model %s %o", modelName, response);
    if (onComplete) {
      if (Array.isArray(response.data))
        onComplete(response.data, response);
      else
        onComplete(response);
    }
    if (setLoading)
      setLoading(false);
  } catch (err) {
    handleFetchError(context, err, setLoading, onError);
  }
}

function handleFetchError(context, reason, setLoading, onError) {
  getLog().debug("Error searching model %o", context);
  getLog().debug("Reason %o", reason);
  if (setLoading)
    setLoading(false);
  if (onError != null) // pass null to make it do nothing on error
    onError(reason);
  else if (onError === undefined)
    showError("Error", reason);
}

export function postUrl(componentContext, url, dataValues, setLoading, onComplete, onError) {
  getLog().debug("Posting url %s context %o components %o", url, componentContext, dataValues);
  if (setLoading)
    setLoading(true);
  callApi(url, "POST", dataValues)
    .then(response => {
      getLog().debug("Post finished.  Response %o", response);
      if (setLoading)
        setLoading(false);
      if (onComplete != null)
        onComplete(response);
    })
    .catch(reason => {
      getLog().debug("Error posting url %o", componentContext);
      getLog().debug(reason);
      if (setLoading)
        setLoading(false);
      if (onError == null)
        showError("Error", reason);
      else
        onError(reason);
    });
}

export function putUrl(componentContext, url, dataValues, setLoading, onComplete, onError) {
  getLog().debug("Put url %s context %o components %o", url, componentContext, dataValues);
  if (setLoading)
    setLoading(true);
  callApi(url, "PUT", dataValues)
    .then(response => {
      getLog().debug("Put url finished.  Response %o", response);
      if (setLoading)
        setLoading(false);
      if (onComplete != null)
        onComplete(response);
    })
    .catch(reason => {
      getLog().debug("Error for Put url %o", componentContext);
      getLog().debug(reason);
      if (setLoading)
        setLoading(false);
      if (onError === undefined)
        showError("Error", reason);
      else
        onError(reason);
    });
}

export async function submitModel(componentContext, setLoading, onComplete, onError, modifyDataMethod, dataMode) {
  const data = componentContext.data;
  let url = modelPrefix + data.modelName;
  if (data.url)
    url = data.url;
  let submitValues = getModelValues(componentContext, false, modifyDataMethod);
  getLog().debug("Submitting values %o to %s with mode %o", submitValues, url, dataMode);
  if (dataMode === DataModes.EDIT)
    putUrl(componentContext, url, submitValues, setLoading, onComplete, onError);
  else
    postUrl(componentContext, url, submitValues, setLoading, onComplete, onError);
}

export function searchModel(componentContext, setLoading, onComplete, onError, modifyDataMethod) {
  const data = componentContext.data;
  let searchValues = getModelValues(componentContext, true, modifyDataMethod);
  getLog().debug("searchModel context %o    values %o", componentContext, searchValues);
  if (setLoading)
    setLoading(true);
  let url = modelPrefix + data.modelName;
  callApi(url, callApiSearchMethod, searchValues)
    .then(response => {
      getLog().debug("Fetched data from model %o", response);
      if (Array.isArray(response.data))
        data.setDataList(response.data);
      else
        data.setDataList(response);
      if (setLoading)
        setLoading(false);
      if (onComplete)
        onComplete(response.data);
    })
    .catch(error => {
      getLog().debug("Error searching model %o  %o", componentContext, error);
      if (setLoading)
        setLoading(false);
      if (onError === undefined)
        showError("Search error", error);
      else
        onError(error);
    });
}

export const withModelLoad = (modelName, targetPropName, BaseComponent, returnAsFunction) => (...props) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState(false);
  useMountEffect(() => { fetchModelData(modelName, null, setLoading, setData) });
  if (loading === undefined || loading === true) {
    if (props && props.showSpinnerDuringLoad)
      return (<Spinner />);
    else
      return null;
  }
  else {
    const dataProp = {};
    dataProp[targetPropName] = data;
    if (returnAsFunction)
      return BaseComponent({ ...dataProp }, { ...props });
    else
      return <BaseComponent {...dataProp} {...props} />;
  }
};

export function createEmptyModelRow() {
  return { modelData: {} };
}

export function callFormatValueFunction(renderFunction, item, value) {
  let f = renderFunction;
  if (typeof f === "string")
    f = getFunctionForIdentifier(renderFunction);
  if (f == null)
    console.log("Couldn't find render function %o", renderFunction);
  return f(item, value);
}
