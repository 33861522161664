import React from "react";
import { DisplayTypes, Image, Table, Column, Label, Panel, SegmentedPanel, TextBox } from "lib/components";
import { useContextData } from "lib/components/Context";
import { ShareOrderButton } from "portal/customer/ShareOrder";
import { BOLButton } from "./BOL";
import { TableStopDetail } from "./TableStopDetail";
import { formatDateTime, getDateFormat, parseDateTime } from "lib/util/Date";
import { QuoteTransitInfoPanel } from "./QuoteTransitInfoPanel";
import { getCompanySettings } from "../../lib/util";

export function QuoteSummaryPanel({stopsVisible, totalVisible, carrierVisible, freightSummaryVisible, otherChargesVisible, ratingDetailsVisible, disclaimerVisible, ...props}) {
  return (
    <SegmentedPanel width={300} marginRight={24} {...props} >
      <StopPanel visible={stopsVisible} />
      <TotalPanel visible={totalVisible} />
      <QuoteOrOrderNumberPanel {...props} />
      <FreightSummaryPanel visible={freightSummaryVisible}/>
      <OtherChargesPanel visible={otherChargesVisible} />
      <CarrierSelectionPanel visible={carrierVisible} transitInfoPanelProps={{...props.transitInfoPanelProps}}/>
      <RatingDetailsPanel visible={ratingDetailsVisible} />
      <Panel visible={disclaimerVisible}>
        <Label field="disclaimer" marginTop={8} marginBottom={4} />
      </Panel>
    </SegmentedPanel>
  );
}

function StopPanel(props) {
  const data = useContextData();
  let shipDate = null;
  if (data != null && data.stops != null && data.stops.length > 0) {
    shipDate = data.stops[0].sched_arrive_early;
    if (shipDate != null)
      shipDate = formatDateTime(parseDateTime(shipDate), getDateFormat(DisplayTypes.DATE, "long"));
  }
  return (
    <Panel {...props}>
      <Label caption="Pickup & Delivery" look="defaultBold4" fillRow />
      <Label caption={shipDate} fillRow />
      <TableStopDetail hideApptInfo={true} hideActualInfo={true} height={225} marginTop={5} field="stops" border={false} fillRow />
    </Panel>
  );
}

function TotalPanel(props) {
  const data = useContextData();  
  let total;
  if(data && getCompanySettings().is_asset_ltl)
    total = data.total_charge;
  else {
    const selectedRate = getSelectedRate(data);
    total = selectedRate != null ? selectedRate.sum_charge_cost_as_amount : "";
  }
  return (
    <Panel {...props}>
      <Label caption="Total" look="defaultBold4" fillRow/>
      <TextBox caption={""} value={total} displayType={DisplayTypes.CURRENCY} usePrintableVersion look="defaultLight9" marginTop={-10} fillRow/>
    </Panel>
  );
}

function CarrierSelectionPanel(props) {
  const data = useContextData();
  const selectedRate = getSelectedRate(data);
  let image = "", carrierName = "", transitDays = "", estDelivery = "";
  if(selectedRate != null)  {
    image = selectedRate.image_bytes;
    carrierName = selectedRate.carrier_name;
    transitDays = selectedRate.transit_days_display_value;
    estDelivery = selectedRate.est_delivery_display_value;
  }
  const transitInfoPanelProps = {...props.transitInfoPanelProps, transitDaysDisplayValue: transitDays, estDeliveryDisplayValue: estDelivery};
  return (
    <Panel {...props}>
      <Label caption="Carrier Selection" look="defaultBold4" fillRow rowBreak/>
      <Label caption={carrierName} look="defaultLight1" marginTop={0} rowBreak/>
      <Image data={image} style={{ maxHeight: 64, maxWidth: 250 }} marginTop={10} rowBreak/>
      <QuoteTransitInfoPanel {...transitInfoPanelProps}/>
    </Panel>
  );
}

export function getSelectedRate(data) {
  for (let i = 0; data != null && data.carrier_rates != null && i < data.carrier_rates.length; i++)
    if (data.carrier_rates[i].selected === true)
      return data.carrier_rates[i];
  return null;
}

function QuoteOrOrderNumberPanel(props) {
  const data = useContextData();
  let numberCaption = props.type + " Number";
  let bolButton;
  if (props.hideBOL !== true) {
    bolButton = <BOLButton orderId={data[props.idField]} fillRow look={props.buttonLook} />
  }
  let shareOrderButton;
  if (props.hideShareOrder !== true) {
    shareOrderButton = <ShareOrderButton orderIdField={props.idField} caption="Share" fillRow noRowBreak look={props.buttonLook}/>;
  }

  return (
    <Panel fillRow>
      <Label caption={numberCaption} look="defaultBold4" />
      <Label field={props.idField} marginTop={-5} nullDisplayValue="--" />
      {shareOrderButton}
      {bolButton}
    </Panel>
   );
}

function FreightSummaryPanel() {
  const data = useContextData();
  let freightClass = getFreightClass(data);
  const assestLtl = getCompanySettings().is_asset_ltl;
  let panelHeight = (assestLtl === true) ? 160 : 135;
  return (
    <Panel height={panelHeight}>
      <Label caption="Freight Summary" look="defaultBold4" fillRow />
      {assestLtl && <Label caption="Pieces" look="defaultLight1" marginTop={0} />}
      {assestLtl && <Label field="pieces" marginTop={-10} />}
      <Label caption="Weight" look="defaultLight1" marginTop={0} />
      <Label field="total_weight_display_value" marginTop={-10} />
      {!assestLtl && <Label caption="Freight Class" look="defaultLight1" marginTop={0} />}
      {!assestLtl && <Label caption={freightClass} marginTop={-10} />}
      {assestLtl && <Label caption="Spots" look="defaultLight1" marginTop={0} />}
      {assestLtl && <Label field="total_spots" marginTop={-10} />}
    </Panel>
  );
}

function OtherChargesPanel() {
  const data = useContextData();
  if (data == null)
  { return null; }
  
  let chargeData = [];
  if (data.other_charges != null) {
    chargeData = [...data.other_charges];
  }
  if (data.freight_charge != null)
  {
    const freightChargeRow = {
      amount: data.freight_charge,
      descr: "Base Charge",
      id: "baseCharge1",
      key: "baseChargeKey",
    };
    chargeData.splice(0, 0, freightChargeRow);
  }
  if (chargeData.length === 0)
  { return null; }

  if(data && getCompanySettings().is_asset_ltl){
    const total = data.total_charge;
    if(total){
      chargeData.push({
        descr: "Total",
        amount: total
      });
    }
  }
  
  return (
    <Panel fillRow>
      <Label caption="Charge Details" look="defaultBold4" fillRow rowBreak/>
      <Table
        data={chargeData}
        headerVisible={false}
        defaultSort={[{field: "sequence"}]}
        allowExport={false}
        showLinesBetweenRows={false}
        fillRow
        border={false}
        rowPadding={0}
        marginBottom={0}
        rowDisplayPanel={OtherChargeDisplayPanel} />
    </Panel>
  );
}

function OtherChargeDisplayPanel(editorProps, row, rowIndex, context)
{
  let descrLabel =  <Label field="descr" look="defaultLight1" margin={0} padding={0} fillRow noRowBreak/>;
  if (rowIndex === context.data.list.length-1)
    descrLabel = <Label field="descr" look="defaultLight2" margin={0} padding={0} fillRow noRowBreak/>;
  let amountLabel = <Label field="amount" look="defaultBold1" align="right" displayType={DisplayTypes.CURRENCY} margin={0} padding={0} fillRow />
  if (rowIndex === context.data.list.length-1)
    amountLabel = <Label field="amount" look="defaultBold2" align="right" displayType={DisplayTypes.CURRENCY} borderTopWidth={1} borderTopColor={"#CDCDCD"} margin={0} padding={0} fillRow />

  return (
    <Panel fillRow margin={0} padding={0}>
      {descrLabel}
      {amountLabel}
    </Panel>
  );
}

function RatingDetailsPanel() {
  const data = useContextData();
  if (data == null)
  { return null; }
  
  let chargeData = [];
  const selectedRate = getSelectedRate(data);
  if (selectedRate != null)
  {
    if (selectedRate.revenue_details != null) {
      chargeData = [...selectedRate.revenue_details];
    }
    chargeData.push({
      descr: "Total",
      charge_cost_as_amount: selectedRate.sum_charge_cost_as_amount
    });
  }
  
  if (chargeData.length === 0)
  { return null; }
  
  return (
    <Panel fillRow>
      <Label caption="Charge Details" look="defaultBold4" fillRow rowBreak/>
      <Table
        data={chargeData}
        headerVisible={false}
        defaultSort={[{field: "sequence"}]}
        allowExport={false}
        showLinesBetweenRows={false}
        fillRow
        border={false}
        rowPadding={0}
        marginBottom={0}
        rowDisplayPanel={RatingDetailsDisplayPanel} />
    </Panel>
  );
}

function RatingDetailsDisplayPanel(editorProps, row, rowIndex, context)
{
  let descrLabel = <Label field="descr" look="defaultLight1" margin={0} padding={0} fillRow noRowBreak/>;
  if (rowIndex === context.data.list.length-1)
  { descrLabel = <Label field="descr" look="defaultLight2" margin={0} padding={0} fillRow noRowBreak/>; }
  
  let amountLabel = <Label field="charge_cost_as_amount" look="defaultBold1" align="right" displayType={DisplayTypes.CURRENCY} margin={0} padding={0} fillRow />;
  if (rowIndex === context.data.list.length-1)
  { amountLabel = <Label field="charge_cost_as_amount" look="defaultBold2" align="right" borderTopWidth={1} borderTopColor={"#CDCDCD"} displayType={DisplayTypes.CURRENCY} margin={0} padding={0} fillRow />; }
  
  return (
    <Panel key={rowIndex} fillRow margin={0} padding={0}>
      {descrLabel}
      {amountLabel}
    </Panel>
  );
}

function getFreightClass(data) {
  let result = undefined;
  if (data != null && data.freight_items != null) {
    for (let i = 0; i < data.freight_items.length; i++) {
      if(data.freight_items[i].nmfc_class_code) {
        const thisFreightClass = data.freight_items[i].nmfc_class_code.field_code_desc;
        if (result === undefined)
          result = thisFreightClass;
        else if (result !== thisFreightClass)
          result = "Multiple";
      }
    }
  }
  return result;
}
