import React, { useState } from "react";
import { Panel, Label, Table, Switch, Field, CheckBox, TextBox, Row, useComponentContext } from "lib/components";
import { StopStatusPanel } from "./StopStatusPanel";
import { MaxDaysOut } from "./MaxDaysOut";
import { getCompanySettings } from "lib/util";
import { setDataContextValue } from "lib/components/DataContainer";
import { getDataFromContext, getDataListFromContext } from "lib/util/ModelUtil";
import { callApi } from "lib/util/api";
import { showWarningSnackbar } from "lib/components/Snackbar";
import { fetchModelData } from "lib/util";
import { formatCityStateZip } from "lib/util/CityUtil";
import { DisplayTypes } from "lib/components";
import { showPickupMinutesSnackbar, showPickupWindowSnackbar } from "portal/customer/StopSnackbars";

const locationErrorMessage = "Please contact " + getCompanySettings().order_contact_name + " at " + getCompanySettings().order_contact_phone + " to have your shippers added to your portal.";
const noLocationMatchesMessage = "You do not have any shippers that match your search criteria.  Please contact " + getCompanySettings().order_contact_name + " at " + getCompanySettings().order_contact_phone + " to have this shipper added to your portal.";
const apptRequiredImage = { image: "Clock", width: 16, height: 16, marginRight: 5 };

export function StopTable(props) {
  const ltlOrder = props.parentType === "ltl-order";
  const ftlOrder = props.parentType === "ftl-order";
  let shipperLocationsInitialValue = null;
  if ((!ltlOrder && !ftlOrder) || !getCompanySettings().enforce_shipper_id) {
    shipperLocationsInitialValue = [];
  }
  const [shipperLocations, setShipperLocations] = useState(shipperLocationsInitialValue);
  if (shipperLocations === null) {
    fetchModelData("dispatch/location-suggestion?text_search=*&locations_only=true", null, null, setShipperLocations);
  }
  const [lastShipperLocationSearchCriteria, setLastShipperLocationSearchCriteria] = useState(null);
  const [lastShipperLocationSearchResults, setLastShipperLocationSearchResults] = useState([]);
  const [isPickupMinutesValid, setPickupMinutesValid] = useState();

  const context = useComponentContext();

  let showDeleteMethod = (context, rowProps, rowIndex, row) => showDelete(context, rowProps, rowIndex, row);
  let getAddSaveIndexesMethod = (rowProps) => getAddSaveIndexes(rowProps);
  let loadInitialRowsMethod = (context, props, loadRows) => loadInitialRows(context, props, loadRows);
  let getNewRowDataMethod = () => getNewRowData();
  let addVisible = true;
  let allowMultipleAdds = true;
  let showDatesTimesMethod = (context, rowProps, rowIndex, row) => showDatesTimes(context, rowProps, rowIndex, row);
  let tableCaption = "Pickups and Deliveries";
  if (props != null)
  {
    if (props.showDelete != null) {
      showDeleteMethod = (context, rowProps, rowIndex, row) => props.showDelete(context, rowProps, rowIndex, row);
    }

    if (props.getAddSaveIndexes != null) {
      getAddSaveIndexesMethod = (rowProps) => props.getAddSaveIndexes(rowProps);
    }

    if (props.loadInitialRows != null) {
      loadInitialRowsMethod = (context, props, loadRows) => props.loadInitialRows(context, props, loadRows);
    }

    if (props.getNewRowData != null) {
      getNewRowDataMethod = () => props.getNewRowData();
    }

    if (props.addVisible != null) {
      addVisible = props.addVisible;
    }

    if (props.allowMultipleAdds != null) {
      allowMultipleAdds = props.allowMultipleAdds;
    }

    if (props.showDatesTimes != null) {
      showDatesTimesMethod = (context, rowProps, rowIndex) => props.showDatesTimes(context, rowProps, rowIndex);
    }

    if (props.tableCaption != null) {
      tableCaption = props.tableCaption;
    }
  }

  const displayEditorPanelProps = {
    showDatesTimes: showDatesTimesMethod,
    parentType: props.parentType,
    lastShipperLocationSearchCriteria: lastShipperLocationSearchCriteria,
    setLastShipperLocationSearchCriteria: setLastShipperLocationSearchCriteria,
    lastShipperLocationSearchResults: lastShipperLocationSearchResults,
    setLastShipperLocationSearchResults: setLastShipperLocationSearchResults,
    setPickupMinutesValid: setPickupMinutesValid,
    isPickupMinutesValid: isPickupMinutesValid
  };
  if (shipperLocations != null) {
    displayEditorPanelProps.shipperLocationsPresent = shipperLocations.length > 0;
  }

  let tableProps = {
    field: "stops",
    caption: tableCaption,
    filterVisible: false,
    columnHeadingsVisible: false,
    allowExport: false,
    toolsInPanel: true,
    displayEditorPanelProps: displayEditorPanelProps,
    border: false,
    rowBorder: false,
    showLinesBetweenRows: false,
    rowMarginBottom: "8px",
    fillRow: true,
    rowsUseTimestampId: true
  }
  if (props.editMode === true)
  {
    tableProps.autoSave = true;
    tableProps.addCaption = "Additional Pickup or Delivery";
    tableProps.addVisible = addVisible;
    tableProps.allowMultipleAdds = allowMultipleAdds;
    tableProps.rowEditor = EditorPanel;
    tableProps.showDelete = showDeleteMethod;
    tableProps.getAddSaveIndexes = getAddSaveIndexesMethod;
    tableProps.loadInitialRows = loadInitialRowsMethod;
    tableProps.getNewRowData = getNewRowDataMethod;
    tableProps.customValidator = (context, rowProps) => validateTable(context, rowProps, props.parentType, displayEditorPanelProps.shipperLocationsPresent, props != null ? props.addlValidator : null, displayEditorPanelProps.isPickupMinutesValid);
    //tableProps.customRowValidator = (context, props, rowIndex) => validateRow(context, props, rowIndex, props.rowValidator);
    tableProps.componentPropsCallback = ltlOrder ? (rowData, rowIndex, componentProps) => ltlOrderComponentProps(rowData, rowIndex, componentProps) : null;
  }
  else
  {
    tableProps.addVisible = false;
    tableProps.rowEditor = DisplayPanel;
  }

  return (
    <Panel fillRow rowBreak>
      <Table {...tableProps} />
    </Panel>
  );
}

function onLocationSearchComplete(shipperLocationsPresent, setLastShipperLocationSearchCriteria, setLastShipperLocationSearchResults, searchCriteria, data)
{
  if (shipperLocationsPresent === false || !getCompanySettings().enforce_shipper_id) {
    return;
  }

  if (setLastShipperLocationSearchCriteria != null) {
    setLastShipperLocationSearchCriteria(searchCriteria);
  }
  if (setLastShipperLocationSearchResults != null) {
    setLastShipperLocationSearchResults(data);
  }
}

function validateTable(context, rowProps, parentType, shipperLocationsPresent, addlTableValidator, isPickupMinutesValid)
{
  let result = true;
  const stops = context.data.list;
  if (stops === null || stops === undefined || stops.length < 2)
  {
    showWarningSnackbar("Please specify at least one pickup and one delivery.", true);
    result = false;
  }
  else if (stops[stops.length-1].modelData.stop_type !== "SO")
  {
    showWarningSnackbar("The last stop must be a delivery.", true);
    result = false;
  }

  if (stops != null)
  {
    result = validateStopEarlyLateTimes(stops) && result;
    result = validateStopsInOrder(stops) && result;
    if(!isPickupMinutesValid){
      showPickupMinutesSnackbar(null,null,null,true);
      result = false;
    }
    result = validatePickupWindow(stops[0]) && result;
    result = validateShipperLocationsPresent(parentType, shipperLocationsPresent) && result;
  }

  if (addlTableValidator != null)
  {
    result = result && addlTableValidator(context, rowProps);
  }
  return result;
}

function validateStopEarlyLateTimes(stops)
{
  for (let x=0 ; x<stops.length ; x++)
  {
    const lateDateTime = getDateTime(stops[x], "sched_arrive_late_time");
    if (lateDateTime == null) {
      continue;
    }

    const earlyDateTime = getDateTime(stops[x], "sched_arrive_early_time");
    if (earlyDateTime > lateDateTime) {
      showWarningSnackbar("Scheduled stop times must be in order i.e. earliest before latest. Please update.", true);
      return false;
    }
  }
  return true;
}

function validateStopsInOrder(stops)
{
  let prevDateTime = null;
  for (let x=0 ; x<stops.length ; x++)
  {
    const earlyDateTime = getDateTime(stops[x], "sched_arrive_early_time");
    if (earlyDateTime == null) {
      continue;
    }
    if (prevDateTime !== null && prevDateTime > earlyDateTime) {
      showWarningSnackbar("Scheduled stop date/times must be in order. Please update.", true);
      return false;
    }
    prevDateTime = earlyDateTime;
  }
  return true;
}

function validatePickupMinutesFromContext(context, setPickupMinutesValid)
{
  if (context.dataIndex !== 0) {
    return;
  }
  let data = getDataFromContext(context);
  return validatePickupMinutes(data, setPickupMinutesValid);
}

function validatePickupMinutes(pickupStop, setPickupMinutesValid)
{
  const earlyDateTime = getDateTime(pickupStop, "sched_arrive_early_time");
  const stopData = pickupStop.modelData != null ? pickupStop.modelData : pickupStop;
  const cityId = stopData.location && stopData.location["city_id"];
  return showPickupMinutesSnackbar(earlyDateTime, cityId, setPickupMinutesValid);
}

function validatePickupWindowFromContext(context)
{
  if (context.dataIndex !== 0) {
    return;
  }
  let data = getDataFromContext(context);
  return validatePickupWindow(data);
}

function validatePickupWindow(pickupStop) {
  const earlyDateTime = getDateTime(pickupStop, "sched_arrive_early_time");
  const lateDateTime = getDateTime(pickupStop, "sched_arrive_late_time");
  const response = showPickupWindowSnackbar(earlyDateTime, lateDateTime);
  return response;
}

function validateShipperLocationsPresent(parentType, shipperLocationsPresent)
{
  if ((parentType === "ltl-order" || parentType === "ftl-order") && getCompanySettings().enforce_shipper_id && shipperLocationsPresent === false) {
    showEnforceShipperCodeSnackbar();
    return false;
  }
  return true;
}

function showEnforceShipperCodeSnackbar() {
  showWarningSnackbar(locationErrorMessage, true, locationErrorMessage, true);
}

function getDateTime(stop, timeField)
{
  if (stop === null || stop === undefined) {
    return null;
  }
  const stopData = stop.modelData != null ? stop.modelData : stop;

  let earlyTime = stopData[timeField];
  if (earlyTime === null || earlyTime === undefined) {
    return null;
  }
  else if (earlyTime.value != null) {
    earlyTime = earlyTime.value;
  }
  let earlyDate = stopData.sched_arrive_date;
  if (earlyDate === null || earlyDate === undefined) {
    return null;
  }
  if (earlyDate.value != null) {
    earlyDate = earlyDate.value;
  }
  const earlyDateTime = new Date(earlyDate);
  const colonPos = earlyTime.indexOf(":");
  earlyDateTime.setHours(earlyTime.substring(0, colonPos));
  earlyDateTime.setMinutes(earlyTime.substring(colonPos+1));
  return earlyDateTime;
}

function getDate(stop)
{
  if (stop === null || stop === undefined) {
    return null;
  }
  const stopData = stop.modelData != null ? stop.modelData : stop;
  let earlyDate = stopData.sched_arrive_date;
  if (earlyDate === null || earlyDate === undefined) {
    return null;
  }
  if (earlyDate.value != null) {
    earlyDate = earlyDate.value;
  }
  return new Date(earlyDate);
}

function showDelete(context, rowProps, rowIndex, row)
{ return rowIndex !== 0; }

function loadInitialRows(context, props, loadRows)
{
  const rows = [
    {modelData:{stop_type: "PU", stop_type_descr: "Pickup"}},
    {modelData:{stop_type: "SO", stop_type_descr: "Delivery"}}
  ];
  loadRows(context, props, rows, false, true);
}

function getNewRowData()
{ return {modelData:{stop_type: "SO", stop_type_descr: "Delivery"}}; }

function getAddSaveIndexes(rowProps)
{
  let index;
  if (rowProps != null && rowProps.length > 0)
  { index = rowProps.length; }
  else
  { index = 0; }
  return {
    addIndex: index,
    saveIndex: index
  }
}

function showDatesTimes(context, rowProps, rowIndex, row)
{ return true; }

function ltlOrderComponentProps(rowData, rowIndex, componentProps) {
  const firstRow = rowIndex === 0;
  let result = {...componentProps};
  result.location.required = true;
  if (result.sched_arrive_date == null) {
    result.sched_arrive_date = {};
  }
  result.sched_arrive_date.required = firstRow;
  if (result.sched_arrive_early_time == null) {
    result.sched_arrive_early_time = {};
  }
  result.sched_arrive_early_time.required = firstRow;
  if (result.sched_arrive_late_time == null) {
    result.sched_arrive_late_time = {};
  }
  result.sched_arrive_late_time.required = false;
  if (result.appt_required == null) {
    result.appt_required = {};
  }
  result.appt_required.required = false;
  if (result.contact_name == null) {
    result.contact_name = {};
  }
  result.contact_name.required = false;
  if (result.phone == null) {
    result.phone = {};
  }
  result.phone.required = false;
  return result;
}

function EditorPanel(editorProps, row, rowIndex, context, toolsPanel)
{
  let stopType;
  if (editorProps.showDelete(null, null, rowIndex) === true) {
    stopType = <Switch caption="" field="stop_type" leftLabel="Pickup" rightLabel="Delivery" noValue="PU" yesValue="SO" onChange={onStopTypeChange} rowBreak />;
  }
  else {
    stopType = <Label field="stop_type_descr" usePrintableVersion look="secondaryBold3" rowBreak/>
  }

  const early = row.modelData["sched_arrive_early"];
  let earlyValue= early;
  if(typeof early ==="object"){
    earlyValue = earlyValue.value;
  }
  const earlyArrivalDate = new Date(earlyValue);
  var timeItems = [];
  var start = new Date(getCompanySettings().earliest_pickup);
  var end = new Date(getCompanySettings().ord_latest_pickup_time);
  if (isNaN(start.getTime())){
    start = new Date();
    start.setHours(8,0,0,0);
  }
  else{
    const temp = start;
    start = new Date();
    start.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
  }
  if(isNaN(end.getTime())){
    end = new Date();
    end.setHours(17,0,0,0);
  }
  else{
    const temp = end;
    end = new Date();
    end.setHours(temp.getHours(), temp.getMinutes(), 0, 0);
  }
  for (var d = start; d <= end; d.setMinutes(d.getMinutes() + 30)) {
    let value = format(d);
    timeItems.push({ caption: value, value: value });
  }

  function format(inputDate) // auxiliary function to format Date object
  {
    var hours = inputDate.getHours();
    var minutes = inputDate.getMinutes();
    //var ampm = hours < 12? "AM" : (hours=hours%12,"PM");
    //hours = hours == 0? 12 : hours < 10? ("0" + hours) : hours;
    hours = hours < 10? ("0" + hours) : hours;
    minutes = minutes < 10 ? ("0" + minutes) : minutes;
    return hours + ":" + minutes; // + " " + ampm;
  }

  const apptReqdImage = <Label image={apptRequiredImage} caption="Appointment Required" />;
  const datesTimesVisible = editorProps.showDatesTimes(context, null, rowIndex);
  let schedArriveDate;
  if (editorProps.parentType === "ftl-order") {
    schedArriveDate = <TextBox caption="Scheduled Date" field="sched_arrive_date" required={datesTimesVisible} displayType="date" placeholder="MM/DD/YYYY" width={200}
        onBlurComplete={(context) => autoFillInfo(context, editorProps.parentType, rowIndex === 0, true, true, true, editorProps.setPickupMinutesValid) }
        onClose={(context) => validatePickupMinutesFromContext(context, editorProps.setPickupMinutesValid)}
        noRowBreak />;  }
  else if (editorProps.parentType === "ltl-order") {
      schedArriveDate = <MaxDaysOut caption="Scheduled Date" field="sched_arrive_date" required={datesTimesVisible} width={200}
      onItemChanged={(context) => autoFillInfo(context, editorProps.parentType, rowIndex === 0, true, true, true, editorProps.setPickupMinutesValid)} fillRow noRowBreak />
  }

  const apptReqdMarginTop = datesTimesVisible ? -10 : 55;
  const contactMarginTop = datesTimesVisible ? -10 : 3;

  let locationsOnlyParam = null;
  let disallowManuallyAddAddress = false;
  if ((editorProps.parentType === "ltl-order" || editorProps.parentType === "ftl-order") && getCompanySettings().enforce_shipper_id && rowIndex === 0) {
    locationsOnlyParam = "locations_only=true";
    disallowManuallyAddAddress = true;
    if (editorProps.shipperLocationsPresent === false) {
      showEnforceShipperCodeSnackbar();
    }
  }

  return (
    <Panel fillRow>
      <StopStatusPanel noRowBreak />
      <Panel borderType="card" fillRow noRowBreak>
        <Panel noRowBreak>
          {stopType}
          <Field
            caption="Location"
            field="location"
            onFocus={() => validateShipperLocationsPresent(editorProps.parentType, editorProps.shipperLocationsPresent)}
            staticParam={locationsOnlyParam}
            disallowManuallyAddAddress={disallowManuallyAddAddress}
            lookupModelResultField={null}
            onSearchComplete={(searchCriteria, data) => onLocationSearchComplete(editorProps.shipperLocationsPresent, editorProps.setLastShipperLocationSearchCriteria, editorProps.setLastShipperLocationSearchResults, searchCriteria, data)}
            onBlurComplete={(context) => {
              autoFillInfo(context, editorProps.parentType, rowIndex === 0, false, false);
              showNoLocationMatchesSnackbar(rowIndex, editorProps.shipperLocationsPresent, editorProps.lastShipperLocationSearchCriteria, editorProps.setLastShipperLocationSearchCriteria, editorProps.lastShipperLocationSearchResults, editorProps.setLastShipperLocationSearchResults);
              validatePickupMinutesFromContext(context, editorProps.setPickupMinutesValid)
            }}
            onDropDownInvisible={(context) => autoFillInfo(context, editorProps.parentType, rowIndex === 0, false)}
            width={485}
            noRowBreak />
          <Panel rowBreak margin={0} marginLeft={15} padding={0}>
            <Row visible={datesTimesVisible}>
              {schedArriveDate}
              <TextBox caption="Early Time" field="sched_arrive_early_time" displayType="time" required={datesTimesVisible} items={timeItems} width={125} onDropDownInvisible={(context) => { validatePickupMinutesFromContext(context, editorProps.setPickupMinutesValid); validatePickupWindowFromContext(context);}} noRowBreak/>
              <TextBox caption="Late Time" field="sched_arrive_late_time" displayType="time" items={timeItems} width={125} onDropDownInvisible={(context) => validatePickupWindowFromContext(context)}/>
            </Row>
            <CheckBox name="checkAppointmentRequired" caption={apptReqdImage} field="appt_required" marginTop={apptReqdMarginTop} marginRight={0} marginLeft={35} rowBreak/>
          </Panel>
          <TextBox field="contact_name" width={265} marginTop={contactMarginTop} noRowBreak/>
          <TextBox field="phone" displayType={DisplayTypes.PHONE} width={150} marginTop={contactMarginTop} noRowBreak/>
        </Panel>
        <Panel fillRow>
          {toolsPanel}
        </Panel>
      </Panel>
    </Panel>
  );
}

function showNoLocationMatchesSnackbar(rowIndex, shipperLocationsPresent, lastShipperLocationSearchCriteria, setLastShipperLocationSearchCriteria, lastShipperLocationSearchResults, setLastShipperLocationSearchResults)
{
  if (rowIndex !== 0 || shipperLocationsPresent === false || !getCompanySettings().enforce_shipper_id) {
    return;
  }
  if (lastShipperLocationSearchCriteria != null && (lastShipperLocationSearchResults == null || lastShipperLocationSearchResults.length === 0))
  { showWarningSnackbar(noLocationMatchesMessage, true); }
  setLastShipperLocationSearchCriteria(null);
}

function autoFillInfo(context, parentType, isShipperStop, updateDateOnly, shouldCallApi = true, shouldValidatePickupMinutes = false, setPickupMinutesValid)
{
  let location = getDataFromContext(context, "location");

  if (location != null)
  {
    if(location.id) {
      if (shouldCallApi)
      {
        const payload = {id: location.id};
        const stopDate = getDate(getDataFromContext(context));
        if (stopDate != null) {
          payload.day_of_week = stopDate.getDay() + "";
        }
        if (parentType === "ltl-order" || parentType === "brokerage-ltl-quote" || parentType === "asset-ltl-quote") {
          payload.ltl = true;
        }
        callApi("api/dispatch/location-auto-fill", "PATCH", payload).then((response) => {
          const rowData = response.data[0];
          if (updateDateOnly !== true)
          {
            setDataContextValue(context, "appt_required", rowData.appt_required === "Y");
            setDataContextValue(context, "contact_name", rowData.name);
            setDataContextValue(context, "phone", rowData.phone);
          }
          if (isShipperStop === true)
          {
            if (rowData.open_time != null) {
              setDataContextValue(context, "sched_arrive_early_time", rowData.open_time, true);
            }
            if (rowData.close_time != null) {
              setDataContextValue(context, "sched_arrive_late_time", rowData.close_time, true);
            }
          }
          if(shouldValidatePickupMinutes){
            validatePickupMinutesFromContext(context, setPickupMinutesValid);
          }
        }).catch(reason => {
          setDataContextValue(context, "appt_required", false);
          setDataContextValue(context, "contact_name", null);
          setDataContextValue(context, "phone", null);
        });
      }
    }
    else {
      location = {...location, "address": location.address1, "location_name": location.name};
      setDataContextValue(context, "location", location);
    }
  }
  else
  {
    setDataContextValue(context, "appt_required", false);
    setDataContextValue(context, "contact_name", null);
    setDataContextValue(context, "phone", null);
  }

  const shipper = context.data.list[0];
  if(shipper != null && shipper.modelData != null && shipper.modelData.location != null) {
    const shipperId = shipper.modelData.location.id;
    const shipperObj = { id: shipperId };
    setDataContextValue(context.data.parentContext, "shipper", shipperObj); // setting shipper.id to be available for dispatch/commodity-product-book lookup
  }
}

function onStopTypeChange(newValue, context)
{
  const newStopTypeDescr = newValue === "PU" ? "Pickup" : "Delivery";
  setDataContextValue(context, "stop_type_descr", newStopTypeDescr);
}

function DisplayPanel(editorProps, row, rowIndex, context, toolsPanel)
{
  const datesTimesVisible = editorProps.showDatesTimes(context, null, rowIndex);
  let dateValue = null;
  let dateCaption = null;
  let earlyTimeValue = null;
  let earlyTimeCaption = null;
  let lateTimeValue = null;
  let lateTimeCaption = null;
  if (datesTimesVisible)
  {
    let data = getDataListFromContext(context)[rowIndex].modelData;
	  dateValue = data.sched_arrive_date_display_value;
    dateCaption = "Scheduled Date";
    earlyTimeValue = data.sched_arrive_early_time;
    if (earlyTimeValue != null) {
      earlyTimeCaption = "Early Time";
    }
    lateTimeValue = data.sched_arrive_late_time;
    if (lateTimeValue != null) {
      lateTimeCaption = "Late Time";
    }
  }

  return (
    <Panel fillRow>
      <StopStatusPanel noRowBreak />
      <Panel borderType="card" fillRow noRowBreak>
        <Panel fillRow noRowBreak>
          <Label field="stop_type_descr" look="secondaryBold3" marginBottom={3} rowBreak/>
  				<Label caption={getLocationName} nullDisplayValue="blankSpace" paddingTop={0} marginTop={-5} look="defaultBold4" />
	  			<Label caption={getLocationAddress} nullDisplayValue="blankSpace" paddingTop={0} marginTop={-5} />
		  		<Label caption={formatCity} nullDisplayValue="blankSpace" paddingTop={0} marginTop={-5} />
        </Panel>
        <Panel fillRow noRowBreak>
          <Label caption={dateValue} nullDisplayValue="blankSpace" look="secondaryBold3" marginBottom={3} rowBreak/>
          <Panel width={240} noRowBreak paddingTop={0} marginTop={-5} >
            <Panel width={70} noRowBreak padding={0} margin={0}>
              <TextBox value={earlyTimeValue} caption={earlyTimeCaption} nullDisplayValue="blankSpace" maxWidth={70} marginTop={0} />
            </Panel>
            <Panel width={70} fillRow rowBreak padding={0} margin={0}>
              <TextBox value={lateTimeValue} caption={lateTimeCaption} nullDisplayValue="blankSpace" maxWidth={70} marginTop={0} />
            </Panel>
            <Label image={apptRequiredImage} caption="Appointment required" marginTop={datesTimesVisible ? -7 : 7} visible={appointmentRequired} />
          </Panel>
        </Panel>
        <Panel maxWidth={250} noRowBreak>
          <TextBox field="contact_name" nullDisplayValue=" " caption="Contact Name" marginTop={30} />
  				<Label field="phone" displayType={DisplayTypes.PHONE} nullDisplayValue="blankSpace" marginTop={-10} />
        </Panel>
      </Panel>
    </Panel>
  );
}

function appointmentRequired(context) {
	let data = getDataFromContext(context);
	if (data.appt_required === "Y")
		return true;
	else
		return false;
}

function getLocationName(context) {
  let data = getDataFromContext(context);
  return data.location.location_name;
}

function getLocationAddress(context) {
  let data = getDataFromContext(context);
  return data.location.address;
}

function formatCity(context) {
  let data = getDataFromContext(context);
	return formatCityStateZip(data.location.city_name, data.location.state, data.location.zip_code);
}
