import React, { useState } from "react";
import { Panel, TextBox, CheckBox, Button } from "lib/components";
import { DataModes, getDataFromContext } from "lib/util/ModelUtil";
import { formatCityStateZip } from "lib/util/CityUtil";
import { setDataContextValue } from "lib/components/DataContainer";
import { handlePostUrl } from "lib/components/Component";

export function AddLocation(props) {
    const [saveAddressVisible, setSaveAddressVisible] = useState(false);
    const [saveAddress, setSaveAddress] = useState(false);
    return(
        <Panel variant="form" modelName = "dispatch/location-add" dataMode={DataModes.ADD} rowBreakDefault {...props}>
            <TextBox fillRow caption="Location Name" field="name" width={425} required={false} onBlur={(context, props, event)=>locationChange(context, props, event, setSaveAddressVisible)}/>
            <TextBox fillRow caption="Street" field="address1" width={425}  onBlur={(context, props, event)=>addressChange(context, props, event, setSaveAddressVisible)}/>
            <TextBox fillRow caption="City/State/Zip" field="city_state_zip" displayType="city" required onItemChanged={(context,items,index)=>onItemChanged(context,items,index,setSaveAddressVisible)}/>
            <CheckBox caption="Save to address book" field="save_address" visible={saveAddressVisible} onChange={(value, context) => setSaveAddress(value)}/>
            <Button caption="Cancel" noRowBreak onClick={()=>props.setDropDownVisible(false)} width={200} marginRight={10}/>
            <Button look="primaryFilledHoverDarker1" caption="Use This Address" width={200} onClick={(event, context, setLoading)=>populateLocation(event, context, setLoading, saveAddress, props)}/>
        </Panel>
    )
}

function locationChange(context, props, event, setSaveAddressVisible){
    const data = getDataFromContext(context);
    if(data.city_state_zip != null && event.target.value != null && data.address1 != null)
        setSaveAddressVisible(true);
    else
        setSaveAddressVisible(false);
}

function addressChange(context, props, event, setSaveAddressVisible){
    const data = getDataFromContext(context);
    if(data.city_state_zip != null && data.name != null && event.target.value != null)
        setSaveAddressVisible(true);
    else
        setSaveAddressVisible(false);
}

function onItemChanged(context,items,index,setSaveAddressVisible) {
    const data = getDataFromContext(context);
    if(context.validateForm()  && data.name != null && data.address1 != null)
        setSaveAddressVisible(true);
    else
        setSaveAddressVisible(false);
}

function populateLocation(event, context, setLoading, saveAddress, props) {
    let location="";
    let locationObject = {};
    if (context && context.data !== null && context.data.list !== null && context.data.list.length > 0) {
        const data = getDataFromContext(context);
        const parentData = getDataFromContext(context.data.parentContext);
        const cityStateZip = formatCityStateZip(data.city_state_zip["name"], data.city_state_zip["state_id"], data.city_state_zip["zip_code"]);
        locationObject.city_name=data.city_state_zip["name"];
        locationObject.state = data.city_state_zip["state_id"];
        locationObject.zip_code = data.city_state_zip["zip_code"];
        locationObject.city_id = data.city_state_zip["id"];
        if (context.validateForm()) {
            // lets build the location string
            if(data.name != null){
                location = data.name;
                locationObject.name = data.name;
            }
            if(data.address1 != null){
                location = location + ", " + data.address1;
                locationObject.address1 = data.address1;
            }
            location = location + " " + cityStateZip;
            if(saveAddress){
                const postProps = {
                    postUrl:"api/dispatch/web-location",
                    postFields:["name", "address1", {source:"city_state_zip.name", target:"city_name"}, {source:"city_state_zip.id", target:"city_id"}, {source:"city_state_zip.state_id", target:"state"}, {source:"city_state_zip.zip_code", target:"zip_code"}],
                    flattenData: true
                };
                handlePostUrl(context, postProps, null);
            }
            parentData[props.field] = locationObject;
            setDataContextValue(parentData, props.field, locationObject);
            props.setValue(location);
            props.setDropDownVisible(false);
        }
    }
}
