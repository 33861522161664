import React, { useState } from "react";
import { PanelCustomerOrderFullDetail } from "./PanelCustomerOrderFullDetail";
import { Panel, ComboBox, TextBox, Button, showSnackbar } from "lib/components";
import { getCompanySettings, getLogger } from "lib/util";
import { AuthType } from "lib/util/AuthType";
import { getQueryParam } from "core/utils/urls";

const log = getLogger("lme.ar.CustomerPortal.PanelViewOrderUnauthenticated");

export function getPageOptions() {
  return {
    auth: AuthType.UNAUTH,
    title: "Load Tracking",
  };
}

export function Tracking() {
  let [searchType, setSearchType] = useState();
  let [searchValue, setSearchValue] = useState();
  const tempToken = getQueryParam("id");
  const searchQueryString =  tempToken != null ? `?token_id=${tempToken}`: window.location.search;
  let display;
  display = <PanelCustomerOrderFullDetail unauthenticated searchQueryString={searchQueryString} fillRow />
  return (
    <Panel fillRow>
      <Panel marginBottom={24} variant="form" fillRow>
        <ComboBox caption="Search type" width={200} items={getComboItems} onChange={(value) => setSearchType(getComboItems()[value].value)} noRowBreak />
        <TextBox caption="Tracking number" name="combo_search_trackingNum" width={240} noRowBreak marginLeft={8} onChange={setSearchValue} />
        <Button caption="Search" look="primaryFilled" height={38} width={100} marginLeft={16} marginTop={24}
          onClick={() => track(searchType, searchValue)} />
      </Panel>
      {display}
    </Panel>
  );
}

function getComboItems() {
  const companySettings = getCompanySettings();
  const comboItems = [
    { caption: "BOL#", value: "BOLNum" },
    { caption: "Consignee ref #", value: "ConsigneeRefNum" },
    { caption: "Order #", value: "OrderNum" },
  ];
  if (companySettings.is_asset_ltl)
    comboItems.push({ caption: "Pro #", value: "ProNum" });
  if (companySettings.is_brokerage_ltl) {
    comboItems.push({ caption: "Carrier pro #", value: "CarrierProNum" });
    comboItems.push({ caption: "Shipper PO #", value: "ShipperPONum" });
  }
  return comboItems;
}

function track(searchType, searchValue) {
  log.debug("Track %o %o", searchType, searchValue);
  if (!searchType || searchType.length === 0 || !searchValue || searchValue.length === 0)
    showSnackbar("You need to enter tracking information to search.");
  else {
    const url = `/portal/customer/Tracking?search_type=${searchType}&search_value=${searchValue}`;
    window.location = url;
    //	history.push(url);
  }
}
