import React from "react";
import { Panel, Table, Column, DisplayTypes, Button, Image } from "lib/components";
import { AuthType } from "lib/util/AuthType";
import { getDataFromContext } from "lib/util";
import { setDataContextValue } from "lib/components/DataContainer";
import { QuoteSummaryPanel, getSelectedRate } from "../QuoteSummaryPanel";
import { QuoteTransitInfoPanel } from "../QuoteTransitInfoPanel";
import { showWarningSnackbar } from "lib/components/Snackbar";

export function CarrierSelection(props) {
  return (
    <Panel caption="Carrier Selection" maxWidth="lg" onContinue={(context, setLoading, handleContinue) => validateCarrierSelection(context, handleContinue)}>
      <QuoteSummaryPanel type="Quote" idField="id" hideBOL={true} hideShareOrder={true} carrierVisible={false} otherChargesVisible={false} ratingDetailsVisible={false} totalVisible={false} noRowBreak />
      <Table
        {...props}
        field="carrier_rates"
        headerVisible={false}
        defaultSort={[{ field: "sum_charge_cost_as_amount", dataType: "currency" }]}
        allowExport={false}
        emptyCaption={"No carrier rates were found."}
        expandComponent={PanelCarrierRateDetails}
        exportName="Carrier Rate Listing"
        cellAlign="center"
        cellVerticalAlign="middle"
      >
        <Column caption="Carrier Information" minWidth={175}>
          <Image marginLeft={25} field="image_bytes" style={{ maxHeight: 64, maxWidth: 150 }} />
        </Column>
        <Column caption="" field="carrier_name" look="subtle1" minWidth={150} />
        <Column caption="Shipping Duration" sortfield="transit_days" sortDataType="integer" minWidth={250}>
          <QuoteTransitInfoPanel transitDaysField="transit_days_display_value" estDeliveryField="est_delivery_display_value"/>
        </Column>
        <Column caption="Charges" field="sum_charge_cost_as_amount" look="primaryBold3" displayType={DisplayTypes.CURRENCY} minWidth={100} />
        <Column caption="Actions">
           <Button
            field="selected"
            width={175}
            caption={getSelectCarrierButtonCaption}
            look={getSelectCarrierButtonLook}
            image={getSelectCarrierButtonImage}
            onClick={(event, context) => selectThisCarrier(event, context)} />
        </Column>
      </Table>
    </Panel>
  )
}

function selectThisCarrier(event, context)
{
  for (let index=0 ; index<context.data.list.length ; index++)
    context.data.list[index].modelData.selected = false;
  setDataContextValue(context, "selected", true);
  document.getElementById('continueButton').click();
}

function getSelectCarrierButtonCaption(context)
{
  const thisRowData = getDataFromContext(context);
  if (thisRowData.selected)
    return "Carrier Selected";
  else
    return "Select and Continue";
}

function getSelectCarrierButtonLook(context)
{
  const thisRowData = getDataFromContext(context);
  if (thisRowData.selected)
    return "secondary1";
  else
    return "primary1";
}

function getSelectCarrierButtonImage(context)
{
  const thisRowData = getDataFromContext(context);
  if (thisRowData.selected)
    return {image:'CheckWithCircle', stroke:'green', fill:"white", width:18, height:18};
  else
    return null;
}

function validateCarrierSelection(context, handleContinue)
{
  const data = getDataFromContext(context);
  const selectedRate = getSelectedRate(data);
  if (selectedRate == null) {
    showWarningSnackbar("Please select a carrier to continue.", false);
  }
  else {
    handleContinue();
  }
}

function PanelCarrierRateDetails(props) {
  return (
    <Panel backgroundColor="white">
      <Table
        field="revenue_details"
        marginTop={1}
        maxWidth={450}
        filterVisible={false}
        allowExport={false}
        emptyCaption={"No carrier charges were found."}
        {...props}>
        <Column caption="Charges" field="charge_cost_as_amount" look="defaultBold4" displayType={DisplayTypes.CURRENCY} minWidth={150} />
        <Column caption="Charge Type" field="descr" look="subtle2" minWidth={200} />
      </Table>
    </Panel>
  );
}

export function getPageOptions() {
  return {
    auth: AuthType.CUSTOMER,
    title: "Carrier Selection",
  };
}
