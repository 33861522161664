import React, { useState } from "react";
import { useTextBoxStyles } from "./TextBoxStyles";
import { isEmptyString, useMountEffect } from "lib/util";
import { fetchCachedModelData, callFormatValueFunction, getDataFromContext } from "lib/util/ModelUtil";
import { getItems } from "./AutoCompleteInput";
import { highlightText } from "../Label";
import { useUnmountEffect } from "lib/util/Hooks";
import { useComponentContext } from "../Context";
import { isCombo } from "./TextBoxUtil";
import { Button, Spinner } from "lib/components";
import { AddLocation } from "portal/general/AddLocation";
import { useEffect } from "react";
import { flatten } from "core/utils/common";

let globalSetVisible;

// yeah, this is happening
const numberText = ["one character", "two characters", "three characters", "four characters", "five characters", "six characters", "seven characters", "eight characters"];

export function DropDown(props) {
  const context = useComponentContext();
  const [showAddLoc, setShowAddLoc] = useState(false);
  const [clientHeight, setClientHeight] = useState(0);
  let classes = useTextBoxStyles();
  let scrollCallback = (event) => {
    if (event.target !== props.listRef.current && event.target.id !== "dropDownRow-0"){
      globalSetVisible(false);
    }
  }
  useMountEffect(() => {
    if (props.lookupModel != null && isCombo(props)) { // should really check if it's a cached mode, not if isCombo()
      let lookupModel = props.lookupModel;
      if (props.searchParam != null) {
        lookupModel = addParam(lookupModel, props.searchParam, context);
      }
      fetchCachedModelData(lookupModel, null, props.setLoading, props.setModelItems);
    }
    globalSetVisible = props.setDropDownVisible;
    document.addEventListener("click", hideGlobalDropDown);
    document.addEventListener("scroll", scrollCallback, true);

  });
  useUnmountEffect(() => {
    document.removeEventListener("click", hideGlobalDropDown);
    document.removeEventListener("scroll", scrollCallback, true);
  });
  useEffect(() => {
    if (showAddLoc) {
      document.removeEventListener("click", hideGlobalDropDown);
      document.removeEventListener("scroll", scrollCallback, true);
    }
  }, [showAddLoc]);
  useEffect(() => {
    if (props.listRef != null && props.listRef.current != null) {
      setClientHeight(props.listRef.current.offsetHeight);
    }
  });
  let style;
  const compWidth = props.contentRef.current.offsetWidth;
  if (props.lookupModelSuggestions == null)
    style = { width: compWidth };
  else {
    style = { minWidth: compWidth };
    if (showAddLoc) {
      style = { minWidth: compWidth, maxHeight: 450 };
    }
  }

  const rect = props.anchor.getBoundingClientRect();
  style.top = rect.bottom + 4;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if (clientHeight > 0 && rect.top - clientHeight > 32 && rect.top + clientHeight > windowHeight - 32)  // this 32 refers to the page padding (I guess  :(   )
    style.top = rect.top - clientHeight - 3;

  if (props.loading)
    return (<div className={classes.listbox} style={style}><Spinner /> </div>);
  const items = getItems(props.items, props.modelItems, props.includeBlankItem, props.lookupModelDisplayField, props.lookupModelResultField, props.staticItem, props.metaData, props.required);
  const rows = [];
  const addLocation = <div id="dropDownRow-0" className={classes.listbox + " " + classes.noMatchingLocation} ref={props.listRef} style={style} ><AddLocation {...props} /></div>;
  let resultsFoundRow;
  for (let i = 0; items != null && i < items.length; i++) {
    let className = classes.dropDownRow;
    if (i === props.dropDownIndex || (i === 0 && props.dropDownIndex < 0))
      className += " " + classes.selectedItem;
    rows.push(getSuggestionRow(context, items, items[i], className, classes.dropDownCell, i, props));
  }
  if (rows.length === 0) {
    let value = props.inputRef.current.value;
    if (props && props.displayType === "location" && value.length >= props.minSearchLength) {
      let manuallyAddAddressButton;
      if (props.disallowManuallyAddAddress !== true) {
        manuallyAddAddressButton = <Button id="addAddress" caption="Manually Add Address" variant="text" color="primary" padding={0} className={classes.buttonAddLocation} onClick={(event) => { event.preventDefault(); setShowAddLoc(true) }} onBlur={(event) => {props.setDropDownVisible(false);}} tabIndex={-1} />;
      }

      let NoMatches = (
        <div id="dropDownRow-0" className={classes.listbox + " " + classes.noMatching + " " + classes.addLocation} ref={props.listRef} style={style} >
          {getNoMatchText(props)}
          {manuallyAddAddressButton}
        </div>
      );
      if (showAddLoc)
        NoMatches = addLocation;
      return NoMatches;
    }
    else
      return <div className={classes.listbox + " " + classes.noMatching} ref={props.listRef} style={style}>{getNoMatchText(props)}</div>
  }
  else {
    if (props && (props.displayType === "location" || props.displayType === "city")) {
      if (showAddLoc)
        return addLocation;

      let manuallyAddAddressButton;
      if (props.disallowManuallyAddAddress !== true) {
        manuallyAddAddressButton = <Button id="addAddress" caption="Manually Add Address" variant="text" color="primary" padding={0} className={classes.buttonAddLocation} onClick={(event) => { event.preventDefault(); setShowAddLoc(true) }} onBlur={(event) => {props.setDropDownVisible(false);}} tabIndex={-1} />;
      }
      resultsFoundRow = (
        <tr key={"row-" + items.length + 1} id={"dropDownRow-" + items.length + 1} tabIndex={-1} ref={props.listRef} className={classes.dropDownRowSticky + " " + classes.addLocation} style={style}>
          Results Found: {rows.length}
          {(props.displayType === "location") && manuallyAddAddressButton}
        </tr>
      );
    }
  }
  return (
    <div className={classes.listbox} id="dropDownBox" ref={props.listRef} style={style}>
      <table className={classes.dropDownTable}>
        <tbody>
          {rows}
          {resultsFoundRow}
        </tbody>
      </table>
    </div>
  );
}

function getSuggestionRow(context, items, item, className, cellClassName, rowIndex, props) {
  const ref = React.createRef();
  props.refs.push(ref);
  const text = getItemText(item, props.lookupModelDisplayField);
  const value = props.inputProps.value;
  let cells = [];
  if (props.lookupModelSuggestions == null) {
    cells.push(<td className={cellClassName} key="cell-0">{text}</td>);
  }
  else if (props.lookupModelSuggestions.rowRenderFunction != null) {
    cells = callFormatValueFunction(props.lookupModelSuggestions.rowRenderFunction, item, value);
  }
  else {
    for (let i = 0; i < props.lookupModelSuggestions.columns.length; i++) {
      const column = props.lookupModelSuggestions.columns[i];
      cells.push(<td key={"cell-" + i} width={column.width} className={cellClassName}>{getItemText(item, column.field, column.renderFunction, value)}</td>);
    }
  }
  return (
    <tr key={"row-" + rowIndex}
      id={"dropDownRow-" + rowIndex}
      tabIndex={-1}
      ref={ref}
      className={className}
      onClick={(event) => {
        event.stopPropagation();
        props.selectItem(context, props, items, rowIndex);
        props.setDropDownVisible(false);
        props.handleBlurOnDropDownClick(context, props, event, props.setFocused);
      }}
    >
      {cells}
    </tr>
  );
}

function getItemText(item, itemField, renderFunction, value) {
  if (renderFunction != null) {
    return callFormatValueFunction(renderFunction, item, value);
  }
  else if (typeof item === "string") {
    return item;
  }
  else if (itemField == null) {
    return item.caption;
  }
  else {
    return highlightText(item[itemField], value);
  }
}

function getNoMatchText(props) {
  let value = props.inputRef.current.value;
  if (isEmptyString(value) && isCombo(props)) {
    return "No records to display";
  }
  if (value == null || value.length < props.minSearchLength) {
    if (props.minSearchLength < numberText.length)
      return "Enter at least " + numberText[props.minSearchLength - 1] + " to search.";
    else
      return "Enter at least " + props.minSearchLength + " to search.";
  }
  else
    return "No matching records";
}

function hideGlobalDropDown(event) {
  if (event.defaultPrevented)
    return;
  globalSetVisible(false);

}

export function addParam(lookupModel, searchParam, context) {
  let result = lookupModel;
  let value;
  let param;
  if (searchParam.value) {
    value = searchParam.value;
    param = searchParam.key;
  }
  else {
    value = searchParam;
    param = searchParam;
  }
  const data = getDataFromContext(context);
  let flattenedData;
  if (data)
    flattenedData = flatten(data);
  const parentData = getDataFromContext(context.data.parentContext);
  let flattenedParent;
  if (parentData)
    flattenedData = flatten(parentData);
  if (data[value])
    result = lookupModel + "?" + param + "=" + data[value];
  else if (flattenedData && flattenedData[value]) {
    result = lookupModel + "?" + searchParam.key + "=" + flattenedData[value];
  }
  else if (flattenedParent && flattenedParent[value]) {
    result = lookupModel + "?" + searchParam.key + "=" + flattenedParent[value];
  }
  return result;
}
