import React, { useState, useRef } from "react";
import { Tab, Panel, Tabset, Switch, ComboBox, Label, showDialog, TextBox, Field, Button, ButtonActions, DisplayTypes } from "lib/components";
import { Map } from "lib/components/Map";
import { TableCustomerOrders } from "./TableCustomerOrders";
import { getCompanySettings, getLogger } from "lib/util";
import { PanelCustomerOrderFullDetail } from "./PanelCustomerOrderFullDetail";
import { QuoteList } from "./QuoteList";
import { getMapFilterProps, filterByDays } from "./CustomerPortalMapFilter";
import { OrderQuoteButtonPanel } from "./OrderQuoteButtonPanel";
import { isPermissionsAllowed } from "lib/util/Permissions";
import { getTheme } from "lib/util/Theme";
import { AuthType } from "lib/util/AuthType";
import { getMainPageContext } from "../../lib/util/MainPageContext";
import { DataModes } from "lib/util/ModelUtil";
import { setDataContextValue } from "lib/components/DataContainer";

const log = getLogger("ar.CustomerPortal");

export function CustomerPortal() {
  let [showAsMap, setShowAsMap] = useState(false);
  let [showListMapToggle, setShowListMapToggle] = useState(true);
  let [tabIndex, setTabIndex] = useState(0);
  let [showDateRange, setShowDateRange] = useState(false);
  const [actualRecordCount, setActualRecordCount] = useState(0);
  const onAdvSearchComplete = useRef();

    React.useEffect(() => {
    if (tabIndex === 4 || tabIndex === 5) {
      setShowListMapToggle(false);
    }
    else {
      setShowListMapToggle(true);
    }
    setShowDateRange(false);
  }, [tabIndex]);

  const [showDest, setShowDest] = useState(false);
  let innerProps = { tabIndex, showAsMap, fillHeight: true, showDest, setShowDest };
  const listOrMapSwitch = showListMapToggle ? <Switch leftLabel="List" leftImage="List" rightLabel="Map" rightImage="MapPin" onChange={setShowAsMap} /> : null;
  const orderStatusItems = [{caption: "All", value:"O"},{caption: "In Transit", value:"P"},{caption: "Scheduled", value:"A"},{caption: "Delivered", value:"D"},
                            {caption: "In Transit & Scheduled", value:"P,A"},{caption: "In Transit & Delivered", value:"P,D"},{caption: "Scheduled & Delivered", value:"A,D"}];
  const orderStatusDefault = orderStatusItems[0].value;
  if (isPermissionsAllowed("InternetModule", "InternetModuleOrderTracking")) {
    let tabsetComponent = (
      <Panel>
        <OrderQuoteButtonPanel paddingRight={32} noRowBreak />
        {listOrMapSwitch}
      </Panel>
    );

    let quotesTab = null;
    if (isPermissionsAllowed("InternetModule", "InternetModuleQuoteEntry"))
    {
      const settings = getCompanySettings();
      if (settings.is_asset_ltl || settings.is_ltl)
      {
        quotesTab = (
          <Tab name="quotesTab" caption={tabIndex===5 ?`Quotes (${actualRecordCount})`: "Quotes"} fillHeight fillRow>
            <QuoteList {...innerProps} setActualRecordCount={setActualRecordCount} filterPlaceholder="Search all Quotes"/>
          </Tab>
        );
      }
    }
    log.debug("advSearchComplete: %o", onAdvSearchComplete.current)
    const displayEta = getDisplayEta();
    return (
      <Tabset name="tabset" fillRow fillHeight onChange={setTabIndex} extraComponent={tabsetComponent} >
        <Tab caption={tabIndex===0 ? `In Transit (${actualRecordCount})`: "In Transit"} fillHeight >
          <TableOrMap modelFilter="status=P" {...innerProps} showETAColumn={displayEta} description="in-transit" showStatusColumn={false} setActualRecordCount={setActualRecordCount} filterPlaceholder="Search all In Transit"/>
        </Tab>
        <Tab caption={tabIndex===1 ? `Scheduled (${actualRecordCount})`:"Scheduled"} fillHeight >
          <TableOrMap modelFilter="status=A" {...innerProps} showETAColumn={displayEta} description="scheduled" showStatusColumn={false} setActualRecordCount={setActualRecordCount} filterPlaceholder="Search all Scheduled"/>
        </Tab>
        <Tab caption={tabIndex===2 ? `Delivered (${actualRecordCount})`:"Delivered"} fillHeight >
          <TableOrMap modelFilter="status=D" {...innerProps} showETAColumn={displayEta} description="delivered" showStatusColumn={false} setActualRecordCount={setActualRecordCount} filterPlaceholder="Search all Delivered"/>
        </Tab>
        <Tab name="ordersTab" caption={tabIndex===3 ?`All Orders (${actualRecordCount})`: "All Orders"} fillHeight fillRow>
          <TableOrMap modelFilter="status=O" {...innerProps} showETAColumn={false} setActualRecordCount={setActualRecordCount} filterPlaceholder="Search All Orders"/>
        </Tab>
        <Tab caption={tabIndex===4 ?`Advanced Search (${actualRecordCount})`: "Advanced Search"} fillHeight fillRow>
          <Panel name="top" modelName="ar/customer-orders" dataMode={DataModes.SEARCH} fillHeight fillRow>
            <Panel variant="form">
              <Switch leftLabel="Single date" rightLabel="Date range" onChange={(value, context) => handleDateSwitchChange(value, context)} />
              <TextBox caption="Scheduled ship date" placeholder={(showDateRange)? "From" : "Single date" } field="ship_date_from" displayType="date" noRowBreak/>
              {showDateRange && <TextBox caption="Scheduled ship date" placeholder="To" field="ship_date_to" displayType="date" noRowBreak/>}
              <Field
                caption="Location"
                field="location"
                displayType={DisplayTypes.LOCATION}
                staticParam="locations_only=true"
                disallowManuallyAddAddress={true}
                lookupModelResultField={null}
                width={450}
                includeBlankItem={false}
                noRowBreak />
              <TextBox caption="Consignee Reference Number" field="orders.consignee_refno" noRowBreak />
              <TextBox caption="Order Status" field="orders.status" items={orderStatusItems} default={orderStatusDefault} includeBlankItem={false} fillHeight noRowBreak/>
              <Button action={ButtonActions.SUBMIT} onComplete={onAdvSearchComplete.current} marginTop={18} />
            </Panel>
            <TableOrMap {...innerProps} onSearchComplete={onAdvSearchComplete} showETAColumn={false} setActualRecordCount={setActualRecordCount} filterPlaceholder="Filter" isAdvancedSearch={true}/>
          </Panel>
        </Tab>
        {quotesTab}
      </Tabset>
    );
  } else {
    return (
      <Panel>
        <Label caption="You do not have permission to track loads." fontSize="xlarge" fontBold fillRow noRowBreak />
        <OrderQuoteButtonPanel />
      </Panel>
    );
  }

  function handleDateSwitchChange(value, context) {
    context.setValue("ship_date_to", null);
    setShowDateRange(value);
  }
}

function TableOrMap({ modelFilter, showAsMap, filter, tabIndex, showDest, setShowDest, description, isAdvancedSearch, ...tableProps }) {
  let modelName = `ar/customer-orders`;
  if(modelFilter)
    modelName = modelName + `?${modelFilter}`;
  const [filterIndex, setFilterIndex] = useState();
  const filterProps = getMapFilterProps(tabIndex, showDest);
  let filterValue = filterIndex;
  if (filterIndex == null)
    filterValue = filterProps.items.length - 1;
  const today = new Date();
  log.debug("Filter props %o %o %o %o", tabIndex, showDest, filterValue, filterProps);
  if (showAsMap) {
    return (
      <Panel fillHeight fillRow>
        <Switch leftLabel="Origin" leftImage="MapPin" rightLabel="Destination" rightImage="MapPinSemiFilled" checked={showDest} onChange={setShowDest} marginTop={14} noRowBreak />
        <ComboBox {...filterProps} selectedIndex={filterValue} onChange={setFilterIndex} width={280} marginLeft={64} />
        <Map name="customerMap" modelName={modelName} {...getMapProps(showDest)}
          filter={data => filterByDays(data, tabIndex, showDest, filterProps.items[filterValue], today)}
          onPinClick={pinClick} setActualRecordCount={tableProps.setActualRecordCount} fillRow fillHeight />
      </Panel>
    );
  }
  else
    return <TableCustomerOrders name="table" description={description} autoSearch={!isAdvancedSearch} modelName={modelName} filter={filter} {...tableProps} fillRow fillHeight />
}

function pinClick(pins) {
  log.debug("Clicked %o", pins);
  let data = []; // not sure how often we'll pass data in as props, but this is complicated - consider making a utility
  pins.forEach(pin => data.push(pin.data));
  if (data.length === 0) // don't know how this will happen, but weird things happen
    return;
  const firstPinData = data[0];
  const dialogProps = { titleBackgroundColor: getTheme().palette.primary.base, titleColor: getTheme().palette.primary.reverse };
  if (data.length === 1) {
    showDialog(`Detail for order ${firstPinData["orders.id"]}`, (
      <PanelCustomerOrderFullDetail data={data} width={1500} />
    ), dialogProps);
  }
  else {
    showDialog(`Orders in ${firstPinData["shipper.city_name"]}, ${firstPinData["shipper.state"]}`, (
      <Panel width={1500} data={data} >
        <TableCustomerOrders fillHeightfillRow />
      </Panel>
    ), dialogProps);
  }
}

function getMapProps(showDest) {
  if (showDest)
    return { latField: "consignee.latitude", longField: "consignee.longitude" };
  else
    return { latField: "shipper.latitude", longField: "shipper.longitude" };
}

export function getPageOptions() {
  return { title: "Customer Portal", auth: [AuthType.CUSTOMER, AuthType.LOCATION] };
}

export function getDisplayEta() {
  const userType = getMainPageContext().user_settings.user_type;

  let displayEta = true;
  let etaSetting = "A";
  if(userType === "C") {
    etaSetting = getMainPageContext().customer_settings.display_eta;
  }
  else {
    etaSetting = getMainPageContext().company_settings.display_eta;
  }

  if(etaSetting === "N")
      displayEta = false;

  return displayEta;
}
